import React from "react";
import { useParams } from "react-router-dom";
import { ProductDocumentationPage } from "../../common/ProductDocumentationPage";

export const WsjfProductDocumentationPage = () => {
  const { articleId } = useParams<{ articleId: string | undefined }>();

  return (
    <ProductDocumentationPage
      articleId={articleId}
      articleCollectionElement="kRGvbsrkRY2YQ6dx2QMA4"
      productPath="wsjf"
      pageTitle="WSJF Plugin for Jira"
      documentTitle="COGNITIFF | WSJF Plugin For Jira | Documentation"
    />
  );
};
