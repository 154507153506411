import TagManager from "react-gtm-module";
import { getCookieConsentValue } from "react-cookie-consent";

window.dataLayer = window.dataLayer || [];
export function gtag() {
  window.dataLayer.push(arguments);
}

export function initializeDefaultConsent() {
  const value =
    getCookieConsentValue() === undefined
      ? "denied"
      : getCookieConsentValue()
      ? "granted"
      : "denied";
  gtag("consent", "default", {
    facebook_storage: value,
    analytics_storage: value,
  });
}

export function initializeGtm() {
  initializeDefaultConsent();
  const tagManagerArgs = {
    gtmId: "GTM-WN54DHC",
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };
  TagManager.initialize(tagManagerArgs);
}

export function updateConsentToAllGranted() {
  gtag("consent", "update", {
    facebook_storage: "granted",
    analytics_storage: "granted",
  });
}

export function updateConsentToAllDenied() {
  gtag("consent", "update", {
    facebook_storage: "denied",
    analytics_storage: "denied",
  });
}
