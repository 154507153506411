import React, { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import * as contentful from "contentful";
import { Article } from "./Article";

type Props = {
  entry: string;
  documentTitle: string;
};

export const ArticlePage: FC<Props> = ({ entry, documentTitle }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(() => {
    contentful
      .createClient({
        space: "78xueahd7f9l",
        accessToken: "XpDP5UobSmZV-xkuJ8AurNRzA8siabAB9f7KRJToWsI",
      })
      .getEntry(entry)
      .then((content) => {
        setContent(content as any);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [entry]);

  return (
    <Container className="decimal-outline">
      {content ? <Article data={content} /> : null}
    </Container>
  );
};
