import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./home.css";
import { Player } from "@lottiefiles/react-lottie-player";
import lightning from "../../../assets/animations/lightning.json";
import ipad from "../../../assets/animations/ipad.json";
import jira from "../../../assets/animations/jira.json";
import marketing from "../../../assets/animations/marketing.json";

export function Home() {
  const options = {
    loop: true,
    autoplay: true,
  };

  return (
    <Container className="home text-center" fluid={true}>
      <Row className="first align-items-center min-vh-100">
        <Col xs={12}>
          <Player style={{ height: "300px" }} src={lightning} {...options} />
          <span style={{ marginBottom: 0, fontSize: "22px", color: "white" }}>
            home of <span style={{ fontStyle: "italic" }}>(cognitiff)</span>{" "}
            technology
          </span>
        </Col>
      </Row>
      <Row className="second align-items-center min-vh-100">
        <Col xs={12}>
          <Player style={{ height: "300px" }} src={ipad} {...options} />
          <span style={{ marginBottom: 0, fontSize: "22px", color: "white" }}>
            <span style={{ fontStyle: "italic" }}>(cognitiff)</span> android and
            iOS applications
          </span>
        </Col>
      </Row>
      <Row className="third align-items-center min-vh-100">
        <Col xs={12}>
          <Player style={{ height: "300px" }} src={jira} {...options} />
          <span style={{ marginBottom: 0, fontSize: "22px", color: "white" }}>
            <span style={{ fontStyle: "italic" }}>(cognitiff)</span> atlassian
            add-ons
          </span>
        </Col>
      </Row>
      <Row className="fourth align-items-center min-vh-100">
        <Col xs={12}>
          <Player style={{ height: "300px" }} src={marketing} {...options} />
          <span style={{ marginBottom: 0, fontSize: "22px", color: "white" }}>
            <span style={{ fontStyle: "italic" }}>(cognitiff)</span> data
            products
          </span>
        </Col>
      </Row>
    </Container>
  );
}
