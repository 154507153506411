import React, { FC, useEffect, useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import * as contentful from "contentful";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Article } from "./Article";

type Props = {
  articleCollectionElement: string;
  productPath: string;
  articleId: string | undefined;
  pageTitle: string;
  documentTitle: string;
};

export const ProductDocumentationPage: FC<Props> = ({
  articleCollectionElement,
  articleId,
  productPath,
  pageTitle,
  documentTitle,
}) => {
  let navigate = useNavigate();
  const [articleCollection, setArticleCollection] = useState<any>();

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(() => {
    contentful
      .createClient({
        space: "78xueahd7f9l",
        accessToken: "XpDP5UobSmZV-xkuJ8AurNRzA8siabAB9f7KRJToWsI",
      })
      .getEntry(articleCollectionElement)
      .then((entry) => {
        setArticleCollection(entry);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [articleCollectionElement]);

  useEffect(() => {
    if (articleId === undefined && articleCollection) {
      navigate(
        `/products/${productPath}/documentation/${articleCollection.fields.article[0].sys.id}`,
        { replace: true }
      );
    }
  }, [articleId, articleCollection, productPath, navigate]);

  const renderLinks = () => {
    return articleCollection.fields.article.map((element: any, idx: any) => {
      return (
        <Nav.Item as="li" key={idx}>
          <LinkContainer
            to={`/products/${productPath}/documentation/${element.sys.id}`}
          >
            <Nav.Link>{element.fields.heading}</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      );
    });
  };

  const renderArticle = () => {
    if (articleCollection && articleId) {
      const arrticleIdx = articleCollection.fields.article.findIndex(
        (article: any) => {
          return article.sys.id === articleId;
        }
      );
      const currentArticle = articleCollection.fields.article[arrticleIdx];
      return <Article data={currentArticle} />;
    } else {
      return null;
    }
  };

  return (
    <Container className="wsjf" fluid={true}>
      <Row>
        <Nav className="col-md-2 d-md-block bg-light sidebar">
          <div className="sticky">
            <h3 className="title d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>{pageTitle}</span>
            </h3>
            <h6 className="heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Documentation</span>
            </h6>
            <Nav as="ul" className="flex-column">
              {articleCollection ? renderLinks() : null}
            </Nav>
          </div>
        </Nav>
        <main role="main" className="col-md-10 ml-sm-auto px-4">
          {renderArticle()}
        </main>
      </Row>
    </Container>
  );
};
