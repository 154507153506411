import React from "react";
import { useParams } from "react-router-dom";
import { ProductDocumentationPage } from "../../common/ProductDocumentationPage";

export const SbafProductDocumentationPage = () => {
  const { articleId } = useParams<{ articleId: string | undefined }>();

  return (
    <ProductDocumentationPage
      articleId={articleId}
      articleCollectionElement="4kyydPaupr3w4wSp1WA4Nk"
      productPath="sort-by-any-field-plugin-for-jira"
      pageTitle="Sort-By-Any-Field Plugin For Jira"
      documentTitle="COGNITIFF | Sort-By-Any-Field Plugin For Jira | Documentation"
    />
  );
};
