import React, { FC, useEffect } from "react";
import { Container } from "react-bootstrap";

export const CaiqPage: FC = () => {
  useEffect(() => {
    document.title = "Security Self Assessment Questionnaire (CAIQ - Lite)";
  }, []);

  return (
    <Container className="decimal-outline">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">
          Security Self Assessment Questionnaire (CAIQ - Lite)
        </h1>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Section Heading</th>
            <th>Control Heading</th>
            <th>CAIQ ID</th>
            <th>Question Text</th>
            <th>Answer</th>
            <th>Notes/Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Application &amp; Interface Security</strong>
            </td>
            <td>
              <strong>Application Security</strong>
            </td>
            <td>
              <strong>AIS-01.2</strong>
            </td>
            <td>
              Do you use an automated source code analysis tool to detect
              security defects in code prior to production?
            </td>
            <td>Yes</td>
            <td>
              We use automation to identify and revise insecure dependencies. If
              any issues cannot be automatically resolved, our engineering teams
              prioritize them for remediation.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>AIS-01.5</strong>
            </td>
            <td>
              (SaaS only) Do you review your applications for security
              vulnerabilities and address any issues prior to deployment to
              production?
            </td>
            <td>Yes</td>
            <td>
              We address any security flaws identified during review or testing
              before deploying to production. These are regarded as a top
              priority within our organization.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Customer Access Requirements</strong>
            </td>
            <td>
              <strong>AIS-02.1</strong>
            </td>
            <td>
              Are all identified security, contractual, and regulatory
              requirements for customer access contractually addressed and
              remediated prior to granting customers access to data, assets, and
              information systems?
            </td>
            <td>Yes</td>
            <td>
              We furnish comprehensive information on this matter through our
              customer agreement and privacy policy available on our website.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Data Integrity</strong>
            </td>
            <td>
              <strong>AIS-03.1</strong>
            </td>
            <td>
              Does your data management policies and procedures require audits
              to verify data input and output integrity routines?
            </td>
            <td>Yes</td>
            <td>
              Our software is designed to verify the accuracy of data input
              before it is consumed and to cleanse API outputs. We validate this
              through thorough end-to-end testing, manual reviews, and
              penetration testing. To prevent substantial data loss in the event
              of a data integrity issue, we backup all storage. Database change
              scripts under source control are used to carefully regulate data
              schema migrations..
            </td>
          </tr>
          <tr>
            <td>
              <strong>Audit Assurance &amp; Compliance</strong>
            </td>
            <td>
              <strong>Independent Audits</strong>
            </td>
            <td>
              <strong>AAC-02.1</strong>
            </td>
            <td>
              Do you allow tenants to view your SOC2/ISO 27001 or similar
              third-party audit or certification reports?
            </td>
            <td>Yes</td>
            <td>
              Although we haven't undergone SOC II or ISO 27001 audits, we plan
              to disclose audit details as much as practical and secure. Our
              website makes available the results of our penetration tests.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>AAC-02.2</strong>
            </td>
            <td>
              Do you conduct network penetration tests of your cloud service
              infrastructure at least annually?
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>AAC-02.3</strong>
            </td>
            <td>
              Do you conduct application penetration tests of your cloud
              infrastructure regularly as prescribed by industry best practices
              and guidance?
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Information System Regulatory Mapping</strong>
            </td>
            <td>
              <strong>AAC-03.1</strong>
            </td>
            <td>
              Do you have a program in place that includes the ability to
              monitor changes to the regulatory requirements in relevant
              jurisdictions, adjust your security program for changes to legal
              requirements, and ensure compliance with relevant regulatory
              requirements?
            </td>
            <td>Yes</td>
            <td>
              We examine the regulatory environment every quarter and modify our
              internal procedures and documentation accordingly.
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Business Continuity Management &amp; Operational Resilience
              </strong>
            </td>
            <td>
              <strong>Business Continuity Testing</strong>
            </td>
            <td>
              <strong>BCR-02.1</strong>
            </td>
            <td>
              Are business continuity plans subject to testing at planned
              intervals or upon significant organizational or environmental
              changes to ensure continuing effectiveness?
            </td>
            <td>Yes</td>
            <td>
              We evaluate our business continuity plan annually or when there
              are significant organizational or environmental changes, and
              revise our internal policies and documentation accordingly.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Policy</strong>
            </td>
            <td>
              <strong>BCR-10.1</strong>
            </td>
            <td>
              Are policies and procedures established and made available for all
              personnel to adequately support services operations’ roles?
            </td>
            <td>Yes</td>
            <td>
              We keep our customer documentation, internal documentation, and
              staff training material updated regularly. We employ source
              control systems to save and publish these materials to maintain
              change tracking. Our engineers periodically rotate into support
              positions to guarantee that everyone is knowledgeable about how to
              assist our clients and services.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Retention Policy</strong>
            </td>
            <td>
              <strong>BCR-11.1</strong>
            </td>
            <td>
              Do you have technical capabilities to enforce tenant data
              retention policies?
            </td>
            <td>Yes</td>
            <td>
              We can configure this both in the back-end application logic and
              through our cloud infrastructure as needed.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>BCR-11.3</strong>
            </td>
            <td>
              Have you implemented backup or recovery mechanisms to ensure
              compliance with regulatory, statutory, contractual or business
              requirements?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>BCR-11.7</strong>
            </td>
            <td>
              Do you test your backup or redundancy mechanisms at least
              annually?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td>
              <strong>Change Control &amp; Configuration Management</strong>
            </td>
            <td>
              <strong>Unauthorized Software Installations</strong>
            </td>
            <td>
              <strong>CCC-04.1</strong>
            </td>
            <td>
              Do you have controls in place to restrict and monitor the
              installation of unauthorized software onto your systems?
            </td>
            <td>Yes</td>
            <td>
              We automate the provisioning of the infrastructure used to provide
              our services. Customers cannot install software. Only a privileged
              set of users have the permissions to provision software.
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Data Security &amp; Information Lifecycle Management
              </strong>
            </td>
            <td>
              <strong>E-commerce Transactions</strong>
            </td>
            <td>
              <strong>DSI-03.1</strong>
            </td>
            <td>
              Do you provide standardized (e.g. ISO/IEC) non-proprietary
              encryption algorithms (3DES, AES, etc.) to tenants in order for
              them to protect their data if it is required to move through
              public networks (e.g., the Internet)?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>DSI-03.2</strong>
            </td>
            <td>
              Do you utilize open encryption methodologies any time your
              infrastructure components need to communicate with each other via
              public networks (e.g., Internet-based replication of data from one
              environment to another)?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Nonproduction Data</strong>
            </td>
            <td>
              <strong>DSI-05.1</strong>
            </td>
            <td>
              Do you have procedures in place to ensure production data shall
              not be replicated or used in non-production environments?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Secure Disposal</strong>
            </td>
            <td>
              <strong>DSI-07.1</strong>
            </td>
            <td>
              Do you support the secure deletion (e.g., degaussing/cryptographic
              wiping) of archived and backed-up data?
            </td>
            <td>Not Applicable</td>
            <td>
              Data is stored on GCP, we are able to request secure deletion from
              that service if required.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>DSI-07.2</strong>
            </td>
            <td>
              Can you provide a published procedure for exiting the service
              arrangement, including assurance to sanitize all computing
              resources of tenant data once a customer has exited your
              environment or has vacated a resource?
            </td>
            <td>Yes</td>
            <td>
              Our Atlassian products support standard methods of cancelling or
              deactivating their addon service if they decide to stop using it.
              Since no customer data is persisted with Cognitiff, we retain no
              customer relevant information or data.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Datacenter Security</strong>
            </td>
            <td>
              <strong>Asset Management</strong>
            </td>
            <td>
              <strong>DCS-01.2</strong>
            </td>
            <td>
              Do you maintain a complete inventory of all of your critical
              assets located at all sites/ or geographical locations and their
              assigned ownership?
            </td>
            <td>Yes</td>
            <td>
              We do not operate a data center or possess any physical critical
              assets. Our business continuity plan identifies our digitally
              critical assets and their ownership.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Controlled Access Points</strong>
            </td>
            <td>
              <strong>DCS-02.1</strong>
            </td>
            <td>
              Are physical security perimeters (e.g., fences, walls, barriers,
              guards, gates, electronic surveillance, physical authentication
              mechanisms, reception desks, and security patrols) implemented for
              all areas housing sensitive data and information systems?
            </td>
            <td>Not Applicable</td>
            <td>
              We operate as a remote-first company, and although we have a
              physical headquarters, it does not contain any data centers or
              sensitive data or information systems.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>User Access</strong>
            </td>
            <td>
              <strong>DCS-09.1</strong>
            </td>
            <td>
              Do you restrict physical access to information assets and
              functions by users and support personnel?
            </td>
            <td>Not Applicable</td>
            <td />
          </tr>
          <tr>
            <td>
              <strong>Encryption &amp; Key Management</strong>
            </td>
            <td>
              <strong>Key Generation</strong>
            </td>
            <td>
              <strong>EKM-02.1</strong>
            </td>
            <td>
              Do you have a capability to allow creation of unique encryption
              keys per tenant?
            </td>
            <td>Yes</td>
            <td>
              Our cloud provider provides encryption at rest for all storage.
              For more information please refer to the{" "}
              <a
                href="https://cloud.google.com/docs/security/encryption/default-encryption"
                rel="nofollow"
              >
                GCP - Default encryption at rest
              </a>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Encryption</strong>
            </td>
            <td>
              <strong>EKM-03.1</strong>
            </td>
            <td>
              Do you encrypt tenant data at rest (on disk/storage) within your
              environment?
            </td>
            <td>Yes</td>
            <td>This feature is provided by our cloud provider.</td>
          </tr>
          <tr>
            <td>
              <strong>Governance and Risk Management</strong>
            </td>
            <td>
              <strong>Baseline Requirements</strong>
            </td>
            <td>
              <strong>GRM-01.1</strong>
            </td>
            <td>
              Do you have documented information security baselines for every
              component of your infrastructure (e.g., hypervisors, operating
              systems, routers, DNS servers, etc.)?
            </td>
            <td>Yes</td>
            <td>
              All of our infrastructure provisioning is automated. Changes to
              these templates are subjec to code review.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Policy</strong>
            </td>
            <td>
              <strong>GRM-06.1</strong>
            </td>
            <td>
              Are your information security policies and procedures made
              available to all impacted personnel and business partners,
              authorized by accountable business role/function and supported by
              the information security management program as per industry best
              practices (e.g. ISO 27001, SOC 2)?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Policy Enforcement</strong>
            </td>
            <td>
              <strong>GRM-07.1</strong>
            </td>
            <td>
              Is a formal disciplinary or sanction policy established for
              employees who have violated security policies and procedures?
            </td>
            <td>Yes</td>
            <td>
              Our employment agreements and policies mandate these actions.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Policy Reviews</strong>
            </td>
            <td>
              <strong>GRM-09.1</strong>
            </td>
            <td>
              Do you notify your tenant's when you make material changes to your
              information security and/or privacy policies?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>GRM-09.2</strong>
            </td>
            <td>
              Do you perform, at minimum, annual reviews to your privacy and
              security policies?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td>
              <strong>Human Resources</strong>
            </td>
            <td>
              <strong>Asset Returns</strong>
            </td>
            <td>
              <strong>HRS-01.1</strong>
            </td>
            <td>
              Upon termination of contract or business relationship, are
              employees and business partners adequately informed of their
              obligations for returning organizationally-owned assets?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Background Screening</strong>
            </td>
            <td>
              <strong>HRS-02.1</strong>
            </td>
            <td>
              Pursuant to local laws, regulations, ethics, and contractual
              constraints, are all employment candidates, contractors, and
              involved third parties subject to background verification?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Employment Agreements</strong>
            </td>
            <td>
              <strong>HRS-03.1</strong>
            </td>
            <td>
              Do your employment agreements incorporate provisions and/or terms
              in adherence to established information governance and security
              policies?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Employment Termination</strong>
            </td>
            <td>
              <strong>HRS-04.1</strong>
            </td>
            <td>
              Are documented policies, procedures, and guidelines in place to
              govern change in employment and/or termination?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Training / Awareness</strong>
            </td>
            <td>
              <strong>HRS-09.5</strong>
            </td>
            <td>
              Are personnel trained and provided with awareness programs at
              least once a year?
            </td>
            <td>Yes</td>
            <td>
              We provide security awareness training to our new hires during
              their onboarding process. Our team members receive additional
              coaching as needed. Our internal "Security Center of Excellence"
              unites employees from all departments to facilitate the sharing of
              timely advice. To enhance our training efforts, we plan to
              introduce a learning management program and an annual refresher
              video course and questionnaire this year.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Identity &amp; Access Management</strong>
            </td>
            <td>
              <strong>Audit Tools Access</strong>
            </td>
            <td>
              <strong>IAM-01.1</strong>
            </td>
            <td>
              Do you restrict, log, and monitor access to your information
              security management systems (e.g., hypervisors, firewalls,
              vulnerability scanners, network sniffers, APIs, etc.)?
            </td>
            <td>Yes</td>
            <td>
              Our cloud providers furnish notifications and logging capabilities
              for abnormal login activity, which our operations and security
              teams respond to. Our information security systems are only
              accessible to authorized engineers.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IAM-01.2</strong>
            </td>
            <td>
              Do you monitor and log privileged access (e.g., administrator
              level) to information security management systems?
            </td>
            <td>Yes</td>
            <td>
              Our information security management systems are hosted on
              cloud-based products, and we can log any access at the
              administrator level using their tooling.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>User Access Policy</strong>
            </td>
            <td>
              <strong>IAM-02.1</strong>
            </td>
            <td>
              Do you have controls in place ensuring timely removal of systems
              access that is no longer required for business purposes?
            </td>
            <td>Yes</td>
            <td>
              We have an offboarding process in place for departing COGNITIFF
              personnel, and only authorized engineers have access to the
              production environment..
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Policies and Procedures</strong>
            </td>
            <td>
              <strong>IAM-04.1</strong>
            </td>
            <td>
              Do you manage and store the identity of all personnel who have
              access to the IT infrastructure, including their level of access?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Source Code Access Restriction</strong>
            </td>
            <td>
              <strong>IAM-06.1</strong>
            </td>
            <td>
              Are controls in place to prevent unauthorized access to your
              application, program, or object source code, and assure it is
              restricted to authorized personnel only?
            </td>
            <td>Yes</td>
            <td>
              Our source code is stored on cloud-based source control providers,
              with most of it being open source. Private repositories can only
              be accessed by approved staff members who must authenticate
              through MFA. We have automated alerts set up to detect any unusual
              access rights events.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IAM-06.2</strong>
            </td>
            <td>
              Are controls in place to prevent unauthorized access to tenant
              application, program, or object source code, and assure it is
              restricted to authorized personnel only?
            </td>
            <td>Yes</td>
            <td>We do not store tenant source code.</td>
          </tr>
          <tr>
            <td />
            <td>User Access Restriction / Authorization</td>
            <td>
              <strong>IAM-08.1</strong>
            </td>
            <td>
              Do you document how you grant, approve and enforce access
              restrictions to tenant/customer credentials following the rules of
              least privilege?
            </td>
            <td>Yes</td>
            <td>
              We have strict policies against granting access to customer
              credentials. In support scenarios, we have automated processes
              that allow authorized engineers to access a tenant. This access is
              closely monitored and audited, and the customer can see when and
              how it was used.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>User Access Reviews</strong>
            </td>
            <td>
              <strong>IAM-10.1</strong>
            </td>
            <td>
              Do you require a periodical authorization and validation (e.g. at
              least annually) of the entitlements for all system users and
              administrators (exclusive of users maintained by your tenants),
              based on the rule of least privilege, by business leadership or
              other accountable business role or function?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>User Access Revocation</strong>
            </td>
            <td>
              <strong>IAM-11.1</strong>
            </td>
            <td>
              Is timely deprovisioning, revocation, or modification of user
              access to the organizations systems, information assets, and data
              implemented upon any change in status of employees, contractors,
              customers, business partners, or involved third parties?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td>
              <strong>Infrastructure &amp; Virtualization Security</strong>
            </td>
            <td>
              <strong>Audit Logging / Intrusion Detection</strong>
            </td>
            <td>
              <strong>IVS-01.1</strong>
            </td>
            <td>
              Are file integrity (host) and network intrusion detection (IDS)
              tools implemented to help facilitate timely detection,
              investigation by root cause analysis, and response to incidents?
            </td>
            <td>Not Applicable</td>
            <td>
              Most of our cloud infrastructure is provided by
              Platform-as-a-Service (PaaS) applications, so the issue of
              anomalous network or egress traffic behavior does not apply.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IVS-01.2</strong>
            </td>
            <td>
              Is physical and logical user access to audit logs restricted to
              authorized personnel?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IVS-01.5</strong>
            </td>
            <td>
              Are audit logs reviewed on a regular basis for security events
              (e.g., with automated tools)?
            </td>
            <td>Yes</td>
            <td>
              The cloud services we use provide alerting for anomalous access
              &amp; failed access attempts, these are human reviewed as
              required. Application error and uptime notifications are also
              reviewed as needed.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Clock Synchronization</strong>
            </td>
            <td>
              <strong>IVS-03.1</strong>
            </td>
            <td>
              Do you use a synchronized time-service protocol (e.g., NTP) to
              ensure all systems have a common time reference?
            </td>
            <td>Not Applicable</td>
            <td>
              The majority of our cloud infrastructure are Google Cloud Platform
              PaaS resources
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>OS Hardening and Base Controls</strong>
            </td>
            <td>
              <strong>IVS-07.1</strong>
            </td>
            <td>
              Are operating systems hardened to provide only the necessary
              ports, protocols, and services to meet business needs using
              technical controls (e.g., antivirus, file integrity monitoring,
              and logging) as part of their baseline build standard or template?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>Production / Non-Production Environments</td>
            <td>
              <strong>IVS-08.1</strong>
            </td>
            <td>
              For your SaaS or PaaS offering, do you provide tenants with
              separate environments for production and test processes?
            </td>
            <td>Yes</td>
            <td>
              Customers are able to activate multiple, separate Cognitiff
              Atlassian addon instances for testing and production, or they can
              use our internal environment modeling features.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IVS-08.3</strong>
            </td>
            <td>
              Do you logically and physically segregate production and
              non-production environments?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Segmentation</strong>
            </td>
            <td>
              <strong>IVS-09.1</strong>
            </td>
            <td>
              Are system and network environments protected by a firewall or
              virtual firewall to ensure business and customer security
              requirements?
            </td>
            <td>Yes</td>
            <td>
              Our public facing services are protected with a WAF service.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>VMM Security - Hypervisor Hardening</strong>
            </td>
            <td>
              <strong>IVS-11.1</strong>
            </td>
            <td>
              Do you restrict personnel access to all hypervisor management
              functions or administrative consoles for systems hosting
              virtualized systems based on the principle of least privilege and
              supported through technical controls (e.g., two-factor
              authentication, audit trails, IP address filtering, firewalls and
              TLS-encapsulated communications to the administrative consoles)?
            </td>
            <td>Yes</td>
            <td>
              We do not use hyperviors as our products run on managed PaaS
              services.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Wireless Security</strong>
            </td>
            <td>
              <strong>IVS-12.1</strong>
            </td>
            <td>
              Are policies and procedures established and mechanisms configured
              and implemented to protect the wireless network environment
              perimeter and to restrict unauthorized wireless traffic?
            </td>
            <td>Yes</td>
            <td>
              Our employees typically work remotely, but our headquarters
              provides authorized devices with WiFi access for occasional
              internet use. No sensitive data or critical infrastructure is
              stored at our headquarters. Access to the WiFi is granted in
              accordance with our WiFi policy.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IVS-12.2</strong>
            </td>
            <td>
              Are policies and procedures established and mechanisms implemented
              to ensure wireless security settings are enabled with strong
              encryption for authentication and transmission, replacing vendor
              default settings (e.g., encryption keys, passwords, SNMP community
              strings)?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>IVS-12.3</strong>
            </td>
            <td>
              Are policies and procedures established and mechanisms implemented
              to protect wireless network environments and detect the presence
              of unauthorized (rogue) network devices for a timely disconnect
              from the network?
            </td>
            <td>Not Applicable</td>
            <td>
              As a remote-first company, we don't possess wireless networks to
              offer or maintain our products, and there are no internal data
              centers or corporate intranet. Access to our production
              environments mandates two-factor authentication over HTTPS
              connections.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Interoperability &amp; Portability</strong>
            </td>
            <td>
              <strong>APIs</strong>
            </td>
            <td>
              <strong>IPY-01.1</strong>
            </td>
            <td>
              Do you publish a list of all APIs available in the service and
              indicate which are standard and which are customized?
            </td>
            <td>Yes</td>
            <td>
              We prioritize building our product with an "API first" approach.
              Our website has comprehensive user documentation and examples of
              how to use our APIs.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Mobile Security</strong>
            </td>
            <td>
              <strong>Approved Applications</strong>
            </td>
            <td>
              <strong>MOS-03.1</strong>
            </td>
            <td>
              Do you have a policy enforcement capability (e.g., XACML) to
              ensure that only approved applications and those from approved
              application stores can be loaded onto a mobile device?
            </td>
            <td>No</td>
            <td>
              Our company is yet to have a Mobile Device Management (MDM) system
              in place, mainly because we are relatively small and encourage our
              staff not to use their personal mobile devices for work purposes.
              Additionally, most of our systems require a larger screen for
              optimal use. However, we plan to work on improving in this area
              and will evaluate the need for an MDM system during our next
              security compliance review.
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Security Incident Management, E-Discovery, &amp; Cloud Forensics
              </strong>
            </td>
            <td>
              <strong>Incident Management</strong>
            </td>
            <td>
              <strong>SEF-02.1</strong>
            </td>
            <td>Do you have a documented security incident response plan?</td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>SEF-02.4</strong>
            </td>
            <td>
              Have you tested your security incident response plans in the last
              year?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Incident Reporting</strong>
            </td>
            <td>
              <strong>SEF-03.1</strong>
            </td>
            <td>
              Are workforce personnel and external business relationships
              adequately informed of their responsibility, and, if required,
              consent and/or contractually required to report all information
              security events in a timely manner?
            </td>
            <td>Yes</td>
            <td>
              Our staff is educated on how to report internal incidents, and our
              customers are notified about service disruptions that affect them
              through our status page. In some situations where only a small
              number of customers are impacted, we may directly contact those
              customers.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>SEF-03.2</strong>
            </td>
            <td>
              Do you have predefined communication channels for workforce
              personnel and external business partners to report incidents in a
              timely manner adhering to applicable legal, statutory, or
              regulatory compliance obligations?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Incident Response Legal Preparation</strong>
            </td>
            <td>
              <strong>SEF-04.4</strong>
            </td>
            <td>
              Do you enforce and attest to tenant data separation when producing
              data in response to legal subpoenas?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td>
              <strong>
                Supply Chain Management, Transparency, and Accountability
              </strong>
            </td>
            <td>
              <strong>Incident Reporting</strong>
            </td>
            <td>
              <strong>STA-02.1</strong>
            </td>
            <td>
              Do you make security incident information available to all
              affected customers and providers periodically through electronic
              methods (e.g., portals)?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Network / Infrastructure Services</strong>
            </td>
            <td>
              <strong>STA-03.1</strong>
            </td>
            <td>
              Do you collect capacity and use data for all relevant components
              of your cloud service offering?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Third Party Agreements</strong>
            </td>
            <td>
              <strong>STA-05.4</strong>
            </td>
            <td>
              Do third-party agreements include provision for the security and
              protection of information and assets?
            </td>
            <td>No</td>
            <td>
              We will examine all future agreements with third parties to ensure
              compliance with this requirement, and we are currently evaluating
              our existing contracts. We plan to assess the progress of this
              during our next security compliance review.
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <strong>STA-05.5</strong>
            </td>
            <td>
              Do you have the capability to recover data for a specific customer
              in the case of a failure or data loss?
            </td>
            <td>Yes</td>
            <td />
          </tr>
          <tr>
            <td />
            <td>
              <strong>Supply Chain Metrics</strong>
            </td>
            <td>
              <strong>STA-07.4</strong>
            </td>
            <td>
              Do you provide tenants with ongoing visibility and reporting of
              your operational Service Level Agreement (SLA) performance?
            </td>
            <td>Not Applicable</td>
            <td>
              Our Atlassian Addons don't offer an SLA or SLA Performance
              monitoring, however we do communicate service disruptions to our
              status page
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Third Party Audits</strong>
            </td>
            <td>
              <strong>STA-09.1</strong>
            </td>
            <td>
              Do you mandate annual information security reviews and audits of
              your third party providers to ensure that all agreed upon security
              requirements are met?
            </td>
            <td>No</td>
            <td>
              All new third party agreements will be reviewed for such
              commitments, and we're in the process of reviewing existing
              contracts. We will review the status of this at our next security
              compliance review.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Threat and Vulnerability Management</strong>
            </td>
            <td>
              <strong>Antivirus / Malicious Software</strong>
            </td>
            <td>
              <strong>TVM-01.1</strong>
            </td>
            <td>
              Do you have anti-malware programs that support or connect to your
              cloud service offerings installed on all of your IT infrastructure
              network and systems components?
            </td>
            <td>No</td>
            <td>
              Most of our system components are provisioned using PaaS services
              that we don't own. We use automated tools to review our source
              code and conduct human reviews to manage threats and
              vulnerabilities. For the few production components that use IaaS,
              we use real-time anti-virus. Employees are required to use default
              operating system anti-virus applications when using
              company-provided hardware.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Vulnerability / Patch Management</strong>
            </td>
            <td>
              <strong>TVM-02.5</strong>
            </td>
            <td>
              Do you have a capability to patch vulnerabilities across all of
              your computing devices, applications, and systems?
            </td>
            <td>Yes</td>
            <td>
              Employees must have automatic updates enabled on their
              company-provided devices. We don't provide mobile technology, like
              smartphones or tablets, to our employees. Our applications are
              automatically patched and deployed using compatible libraries, but
              if they're not compatible, it's done manually.
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <strong>Mobile Code</strong>
            </td>
            <td>
              <strong>TVM-03.1</strong>
            </td>
            <td>
              Is mobile code authorized before its installation and use, and the
              code configuration checked, to ensure that the authorized mobile
              code operates according to a clearly defined security policy?
            </td>
            <td>Not Applicable</td>
            <td>
              We don't issue employees with mobile devices such as mobile phones
              or tablets.
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};
