import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ArticlePage } from "./common/ArticlePage";
import { NotFound } from "./pages/not-found/NotFound";
import { Home } from "./pages/home/Home";
import { WsjfProductDocumentationPage } from "./pages/wsjf/WsjfProductDocumentationPage";
import { SbafProductDocumentationPage } from "./pages/sort-by-any-field/SbafProductDocumentationPage";
import { ContactUs } from "./contact/ContactUs";
import { CaiqPage } from "./pages/caiq/CaiqPage";
import { SecurityPolicyPage } from "./pages/security-policy/SecurityPolicyPage";

export const Router = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route
      path="atlassian-plugin-security-bug-fix-sla"
      element={
        <ArticlePage
          entry="1j0Mo1Yc5bvznAtcc4ibIh"
          documentTitle="COGNITIFF - Atlassian Plugin Security Bug Fix SLA"
        />
      }
    />
    <Route path="caiq" element={<CaiqPage />} />
    <Route path="security-policy" element={<SecurityPolicyPage />} />
    <Route
      path="atlassian-plugin-sla"
      element={
        <ArticlePage
          entry="7xToZDxJZq1jemUc9dsHF6"
          documentTitle="COGNITIFF - Atlassian Plugin SLA"
        />
      }
    />
    <Route
      path="privacy-policy"
      element={
        <ArticlePage
          entry="7toROgFxL5oMbmx69grNEj"
          documentTitle="COGNITIFF - Privacy Policy"
        />
      }
    />
    <Route path="contact" element={<ContactUs />} />
    <Route path="products" element={<Navigate replace to="wsjf" />} />
    <Route
      path="products/wsjf"
      element={<Navigate replace to="documentation" />}
    />
    <Route
      path="products/wsjf/documentation"
      element={<WsjfProductDocumentationPage />}
    >
      <Route path=":articleId" element={<WsjfProductDocumentationPage />} />
    </Route>
    <Route
      path="products/sort-by-any-field-plugin-for-jira"
      element={<Navigate replace to="documentation" />}
    />
    <Route
      path="products/sort-by-any-field-plugin-for-jira/documentation"
      element={<SbafProductDocumentationPage />}
    >
      <Route path=":articleId" element={<SbafProductDocumentationPage />} />
    </Route>
    <Route
      path="atlassian-plugin-eula"
      element={
        <ArticlePage
          entry="3R04kNc8WfCqSK84aTOH0v"
          documentTitle="COGNITIFF - Atlassian Plugin EULA"
        />
      }
    />
    <Route path="*" element={NotFound} />
  </Routes>
);
