import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../assets/ci/vendor_logo_72x72.png";
import CookieConsent from "react-cookie-consent";
import {
  updateConsentToAllGranted,
  updateConsentToAllDenied,
} from "../gtm/gtm";
import { Router } from "./Router";
import { LinkContainer } from "react-router-bootstrap";

export function App() {
  return (
    <>
      <CookieConsent
        enableDeclineButton
        flipButtons
        acceptOnScroll
        buttonStyle={{ background: "#FFAB00", borderRadius: "3px" }}
        declineButtonStyle={{ background: "none", color: "#cecece" }}
        onAccept={() => updateConsentToAllGranted()}
        onDecline={() => updateConsentToAllDenied()}
        buttonClasses="btn btn-primary"
      >
        This site uses cookies to improve your browsing experience, perform
        analytics and research, and conduct advertising. If you prefer to opt
        out, please click I decline. Otherwise, scrolling the website, or
        clicking I understand indicates you agree to our use of cookies on your
        device.
      </CookieConsent>
      <Navbar variant="dark" expand="sm" sticky="top">
        <LinkContainer to="/">
          <Navbar.Brand className="p-0">
            <img src={logo} alt="" className="logo" />
            COGNITIFF
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <LinkContainer to="/products/*">
              <NavDropdown align="end" title="products">
                <LinkContainer to="/products/wsjf/documentation/6f0LbfHqWkjn9idDtyxKiy">
                  <NavDropdown.Item>WSJF Plugin for Jira</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/products/sort-by-any-field-plugin-for-jira/documentation/6uFtnSKTViAUsJwbjBOsk9">
                  <NavDropdown.Item>
                    Sort-by-any-Field Plugin for Jira
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </LinkContainer>
            <Nav.Item>
              <LinkContainer to="/contact">
                <Nav.Link active={false}>contacts</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="min-vh-100">
        <Router />
      </div>

      <Navbar variant="dark" bg="dark" className="justify-content-center">
        <Navbar.Text>
          Epic Technology Limited ® {new Date().getFullYear()}
        </Navbar.Text>
      </Navbar>
    </>
  );
}
