import React, { useEffect, useState } from "react";
import { Button, Form, Container, Alert } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required().max(10000).required(),
});

type FormikValues = {
  name: string;
  email: string;
  message: string;
};

export function ContactUs() {
  const [isSubmitOk, setIsSubmitOk] = useState<boolean | null>(null);

  useEffect(() => {
    document.title = "COGNITIFF - Contact Us";
  }, []);

  const onSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    const body = {
      name: values.name,
      email: values.email,
      message: values.message,
    };
    setIsSubmitOk(null);
    fetch(
      "https://godl1spaug.execute-api.eu-central-1.amazonaws.com/prod/email",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    )
      .then(() => {
        setIsSubmitOk(true);
        actions.resetForm();
      })
      .catch(() => {
        setIsSubmitOk(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  return (
    <Container className="contact min-vh-100 d-flex justify-content-center align-items-center">
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <div className="form-container">
            <div className="head d-flex flex-wrap align-content-center">
              <h1 className="w-100">Contact Us</h1>
              <span className="text-muted">
                Please write your message by filling out the form below
              </span>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group
                style={{ marginBottom: "10px" }}
                controlId="validationFormik01"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Last"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                style={{ marginBottom: "10px" }}
                controlId="validationFormik02"
              >
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="user@example.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                style={{ marginBottom: "10px" }}
                controlId="validationFormik03"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Please enter your message..."
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.message && touched.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              {isSubmitOk == null ? null : isSubmitOk ? (
                <Alert variant="success ">Your message has been sent.</Alert>
              ) : (
                <Alert variant="dark">
                  An error occured. Please try again.
                </Alert>
              )}
              <Button
                type="submit"
                className="mx-auto d-block text-uppercase fw-bold"
                variant="outline-dark"
              >
                {isSubmitting ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
                {isSubmitting ? " Loading..." : "Submit"}
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </Container>
  );
}
