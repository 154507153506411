import React, { FC, Fragment } from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { N500 } from "@atlaskit/theme/colors";

import Flag from "@atlaskit/flag";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { file } = node.data.target.fields;
      return <img className="img-fluid screenshot" src={file.url} alt="" />;
    },
    [BLOCKS.QUOTE]: (_node: any, children: any) => (
      <div className="info-box">
        <Flag
          appearance="info"
          icon={<InfoIcon label="Info" secondaryColor={N500} />}
          id="info"
          key="info"
          title={children}
        />
      </div>
    ),
  },
};

export const Article: FC<{ data: any }> = ({ data }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.fields.heading}</h1>
      </div>
      {documentToReactComponents(data.fields.content, options)}
    </Fragment>
  );
};
